<template>
  <div>
    <el-select :value="currentValue" :placeholder="$t('permission.selectPlaceholder')" @input="handleModelInput">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="disabled"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    name: String,
    placeholder: {
      default: '',
      type: String
    },
    required: {
      default: true,
      type: Boolean
    },
    value: [String, Number]
  },
  data() {
    return {
      currentValue: this.value,
      options: [
        {
          label: this.$i18n.t('permission.none'),
          value: 'n'
        },
        {
          label: this.$i18n.t('permission.read'),
          value: 'r'
        },
        {
          label: this.$i18n.t('permission.write'),
          value: 'w'
        },
        {
          label: this.$i18n.t('permission.execute'),
          value: 'x'
        }
      ]
    };
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    handleModelInput(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>
<style scoped>
.material-label {
  color: #9e9e9e;
}
</style>
